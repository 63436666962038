@charset "UTF-8";
/*---------------------------------------*\
		Styles et réglages globaux
\*---------------------------------------*/
/*------------------------------------*\
		Easing
\*------------------------------------*/
/*---------------------------------------*\
		Positions & dispositions
\*---------------------------------------*/
/*---------------------------------------*\
		Typographie
\*---------------------------------------*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width: 0            The breakpoint minimum to activate the responsive typography
 * @param  {integer} $max-width: 2560         The breakpoint maximum to activate th responsive typography
 * @param  {integer} $min-font:  12           The minimum font-size for the element
 * @param  {integer} $max-font:  16           The maximum font-size for the element
 * @return {void}
 */
/*---------------------------------------*\
		Triangles
\*---------------------------------------*/
/*---------------------------------------*\
		SVG Responsive
\*---------------------------------------*/
/*
Utilitaire pour le padding hack qui permet d'avoir des fichiers `*.svg` responsive.
Plus d'infos ici : http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
*/
/*------------------------------------*\
		Gradients
\*------------------------------------*/
/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
/*------------------------------------*\
		Custom scrollbar mixin
\*------------------------------------*/
/*------------------------------------*\
		Custom mixin
\*------------------------------------*/
/*------------------------------------*\
		Page d'accueil
\*------------------------------------*/
#loader-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #060023;
  z-index: 100000;
}

#loader-screen #logo-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 650px;
  max-width: 100%;
  margin: auto;
}

@media (max-width: 991.98px) {
  #loader-screen #logo-loader {
    width: 80%;
  }
}

header.header {
  background: transparent;
}

main {
  padding-top: 0;
  overflow-x: initial;
}

section {
  overflow: hidden;
}

#fp-nav {
  right: 0 !important;
}

@media (max-width: 1199.98px) {
  #fp-nav {
    display: none !important;
  }
}

#fp-nav ul li {
  width: 60px;
  height: 25px;
  margin: 0;
  margin-bottom: 55px;
  padding-right: 45px;
  padding: 0;
}

#fp-nav ul li:last-child {
  margin-bottom: 0;
}

#fp-nav ul li a span {
  display: none;
}

#fp-nav ul li .fp-tooltip {
  width: auto;
  right: 0 !important;
  opacity: 1;
  font-family: "Nimbus Sans L";
  font-weight: bold;
  font-size: 13px;
  color: #202020;
}

#fp-nav ul li .fp-tooltip .hover {
  display: none;
}

#fp-nav ul li .fp-tooltip:after {
  display: block;
  opacity: 0;
  width: 60px;
  height: 2px;
  margin-top: 6px;
  content: " ";
  background-image: url("/wp-content/themes/tiz-wmp/assets/svg/layout/line.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

#fp-nav ul li a:hover + .fp-tooltip {
  right: 20px !important;
}

#fp-nav ul li a:hover + .fp-tooltip .hover {
  display: inline-block;
}

#fp-nav ul li a:hover + .fp-tooltip:after {
  opacity: 1;
}

#fp-nav ul li a.active + .fp-tooltip {
  right: 20px !important;
}

#fp-nav ul li a.active + .fp-tooltip:after {
  opacity: 1;
}

#discover-company {
  position: fixed;
  left: 25px;
  top: 50%;
  z-index: 1000;
  font-size: 14px;
  transform-origin: 0 50%;
  transform: rotate(-90deg) translate(-50%, 50%);
  font-weight: bold;
  letter-spacing: 0.3em;
  color: rgba(49, 49, 49, 0.45);
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-width: 1199.98px) {
  #discover-company {
    display: none;
  }
}

#discover-company:hover, #discover-company:focus, #discover-company:active {
  color: #313131;
}

#discover-company img {
  width: 19px;
  height: 19px;
}

#discover-company img.white {
  display: none;
}

body.white #fp-nav .fp-tooltip {
  color: #fff;
}

body.white #fp-nav .fp-tooltip:after {
  background-image: url("/wp-content/themes/tiz-wmp/assets/svg/layout/line-white.svg");
}

body.white #discover-company {
  color: rgba(255, 255, 255, 0.45);
}

body.white #discover-company img {
  display: none;
}

body.white #discover-company img.white {
  display: inline-block;
}

body.white #discover-company:hover, body.white #discover-company:focus, body.white #discover-company:active {
  color: #fff;
}

body.discover-white #discover-company {
  color: rgba(255, 255, 255, 0.45);
}

body.discover-white #discover-company img {
  display: none;
}

body.discover-white #discover-company img.white {
  display: inline-block;
}

body.discover-white #discover-company:hover, body.discover-white #discover-company:focus, body.discover-white #discover-company:active {
  color: #fff;
}

#header-section {
  background: #060023;
  text-align: center;
  box-shadow: -16px 25px 65px 0px rgba(11, 12, 17, 0.2);
}

#header-section #logo {
  width: 650px;
  max-width: 100%;
  margin-bottom: 40px;
}

@media (max-width: 991.98px) {
  #header-section #logo {
    width: 80%;
    margin-top: 30px;
  }
}

#header-section .text {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 991.98px) {
  #header-section .text {
    margin-bottom: 20px;
  }
}

#header-section button {
  color: rgba(255, 255, 255, 0.9);
}

#header-section #background-video {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  opacity: 0.37;
}

@media (max-width: 991.98px) {
  #header-section #background-video {
    position: absolute;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("/wp-content/themes/tiz-wmp/assets/img/layout/bg-tablette-portrait.jpg");
    opacity: 1;
  }
}

#header-section #background-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 991.98px) {
  #header-section #background-video video {
    display: none;
  }
}

#header-section #mouse-scroll {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  text-align: center;
  animation: MoveUpDown 3s linear infinite;
}

@media (max-width: 767.98px) {
  #header-section #mouse-scroll {
    display: none;
  }
}

#header-section #mouse-scroll img {
  display: block;
  margin: 0 auto;
}

#header-section #mouse-scroll img.mouse {
  width: 22px;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 20px;
  }
  50% {
    bottom: 40px;
  }
}

#services-section {
  background: #060023;
  color: #fff;
}

#services-section h2 {
  margin-bottom: 30px;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: normal;
  font-size: 48px;
  line-height: 64px;
  text-shadow: 0px 18px 59px black;
}

@media (max-width: 1440.98px) {
  #services-section h2 {
    font-size: 48px;
  }
}

@media (max-width: 991.98px) {
  #services-section h2 {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  #services-section h2 {
    font-size: 24px;
    line-height: 36px;
  }
}

@media (max-height: 800px) {
  #services-section h2 {
    margin-bottom: 20px;
  }
}

@media (min-width: 1441px) {
  #services-section h2 strong {
    display: block;
    font-size: 72px;
  }
}

@media (max-height: 800px) {
  #services-section h2 strong {
    display: inline-block;
    font-size: 48px;
  }
}

@media (max-width: 991.98px) {
  #services-section h2 strong {
    display: block;
    font-size: 48px;
  }
}

@media (max-width: 767.98px) {
  #services-section h2 strong {
    display: block;
    font-size: 36px;
  }
}

#services-section #pattern-bottom {
  position: absolute;
  width: 100%;
  height: 300px;
  bottom: 30px;
  background-image: url("/wp-content/themes/tiz-wmp/assets/svg/layout/pattern-services.svg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.03;
}

#services-section #list-services .owl-stage {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#services-section #list-services .owl-stage .owl-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto !important;
}

#services-section #list-services .owl-stage .owl-item .service {
  text-align: center;
  padding-bottom: 80px;
  padding-left: 15px;
  padding-right: 15px;
}

#services-section #list-services .owl-stage .owl-item .service .picto {
  display: block;
  height: 100px;
  margin: auto;
  opacity: 0.29;
}

@media (max-width: 1440.98px) {
  #services-section #list-services .owl-stage .owl-item .service .picto {
    height: 80px;
  }
}

@media (max-height: 800px) {
  #services-section #list-services .owl-stage .owl-item .service .picto {
    height: 80px;
  }
}

#services-section #list-services .owl-stage .owl-item .service .title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.6em;
  font-size: 18px;
  line-height: 26px;
  opacity: 0.9;
}

@media (max-height: 800px) {
  #services-section #list-services .owl-stage .owl-item .service .title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 991.98px) {
  #services-section #list-services .owl-stage .owl-item .service .title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

#services-section #list-services .owl-stage .owl-item .service .description {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.68;
}

@media (max-width: 1440.98px) {
  #services-section #list-services .owl-stage .owl-item .service .description {
    font-size: 14px;
    line-height: 16px;
  }
}

#services-section #list-services .owl-stage .owl-item .service .button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.9;
}

#services-section #list-services .owl-stage .owl-item .service .button:hover, #services-section #list-services .owl-stage .owl-item .service .button:focus, #services-section #list-services .owl-stage .owl-item .service .button:active {
  opacity: 1;
}

#services-section #list-services .owl-nav {
  position: absolute;
  top: 80px;
  width: 100%;
  color: #fff;
  font-size: 32px;
  z-index: 1;
}

#services-section #list-services .owl-nav img {
  height: 35px;
}

#services-section #list-services .owl-nav .owl-prev {
  float: left;
  left: 0px;
}

#services-section #list-services .owl-nav .owl-next {
  float: right;
  right: 0px;
}

#services-section #list-services .owl-nav .disabled img {
  opacity: 0.5;
}

#bourse-section {
  background: #f0f0f0;
}

@media (max-width: 767.98px) {
  #bourse-section {
    background: #fff;
  }
}

#bourse-section h2 {
  margin-bottom: 60px;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: normal;
  font-size: 48px;
  line-height: 64px;
}

@media (max-width: 991.98px) {
  #bourse-section h2 {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  #bourse-section h2 {
    font-size: 24px;
    line-height: 36px;
  }
}

@media (max-height: 800px) {
  #bourse-section h2 {
    margin-bottom: 20px;
  }
}

@media (min-width: 1441px) {
  #bourse-section h2 strong {
    display: block;
    font-size: 72px;
  }
}

@media (max-height: 800px) {
  #bourse-section h2 strong {
    display: inline-block;
    font-size: 48px;
  }
}

@media (max-width: 991.98px) {
  #bourse-section h2 strong {
    display: block;
    font-size: 48px;
  }
}

@media (max-width: 767.98px) {
  #bourse-section h2 strong {
    display: block;
    font-size: 36px;
  }
}

#bourse-section #filters-type {
  margin-bottom: 15px;
}

#bourse-section #filters-type .filter-type {
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  #bourse-section #filters-type .filter-type {
    margin-right: 0;
    font-size: 12px;
  }
}

#bourse-section #filters-type .filter-type:last-child {
  margin-right: 0;
}

#bourse-section .sep {
  margin-top: 6px;
  padding: 10px;
  padding-bottom: 5px;
  font-weight: bold;
  background: #e8e8e8;
}

#bourse-section .list-table {
  display: none;
  width: 100%;
  border-spacing: 0 6px;
}

#bourse-section .list-table.loading {
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

#bourse-section .list-table.active {
  display: block;
}

#bourse-section .list-table .line {
  width: 100%;
  margin-top: 6px;
  cursor: pointer;
  border: 1px solid #090909;
  transition: all .3s;
}

#bourse-section .list-table .line:after {
  content: "";
  clear: both;
  display: block;
}

#bourse-section .list-table .line:nth-child(-n+5) {
  display: block;
}

#bourse-section .list-table .line:first-child {
  margin-top: 0;
}

#bourse-section .list-table .line > div {
  float: left;
  width: -webkit-calc(100%/3);
  width: -moz-calc(100%/3);
  width: calc(100%/3);
  padding: 20px 20px;
  padding-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  vertical-align: middle;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #bourse-section .list-table .line > div {
    padding: 12px 12px;
    padding-bottom: 4px;
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  #bourse-section .list-table .line > div {
    font-size: 14px;
  }
}

#bourse-section .list-table .line > div.green {
  color: #64b614;
}

#bourse-section .list-table .line > div.red {
  color: #b6142b;
}

#bourse-section .list-table .line > div:nth-child(5) {
  font-size: 13px;
}

#bourse-section .list-table .line.active, #bourse-section .list-table .line:hover {
  border-color: #260e5b;
  border-width: 3px;
}

#bourse-section #chart {
  height: 100%;
  max-height: 408px;
  width: 100%;
  background: rgba(9, 9, 9, 0.03);
}

@media (max-width: 991.98px) {
  #bourse-section #chart {
    height: 360px;
    margin-bottom: 60px;
  }
}

@media (max-width: 767.98px) {
  #bourse-section #chart {
    height: 400px;
    margin-bottom: 80px;
  }
}

#bourse-section #chart.loading {
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

#bourse-section #chart .amcharts-zoom-out-bg, #bourse-section #chart .amcharts-zoom-out-image, #bourse-section #chart .amcharts-zoom-out-label {
  display: none;
}

#bourse-section #filters-period {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
}

@media (max-width: 991.98px) {
  #bourse-section #filters-period {
    top: 20px;
    left: 0;
  }
}

#bourse-section #filters-period .filter-period {
  display: inline-block;
  margin: 0 3px;
  padding: 10px 12px;
  padding-bottom: 6px;
  background: transparent;
  color: #313131;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

@media (max-width: 767.98px) {
  #bourse-section #filters-period .filter-period {
    margin: 0;
    font-size: 14px;
  }
}

#bourse-section #filters-period .filter-period:hover, #bourse-section #filters-period .filter-period:focus, #bourse-section #filters-period .filter-period:active, #bourse-section #filters-period .filter-period.active {
  background: #090909;
  color: #efefef;
}

#bourse-section .see-all-bourse {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  padding: 25px 50px;
}

@media (max-width: 767.98px) {
  #bourse-section .see-all-bourse {
    position: relative;
    width: 100%;
  }
}

#bourse-section .see-all-bourse img {
  height: 14px;
  margin-left: 5px;
}

#proprietes-section {
  background: #f0f0f0;
}

@media (max-width: 767.98px) {
  #proprietes-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

#proprietes-section .fixed-position {
  position: absolute;
  top: 140px;
  left: 0;
  width: 50%;
  padding-left: 5%;
  padding-right: 5%;
}

@media (max-width: 1440.98px) {
  #proprietes-section .fixed-position {
    top: 80px;
  }
}

@media (max-width: 991.98px) {
  #proprietes-section .fixed-position {
    width: 100%;
    top: 80px;
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  #proprietes-section .fixed-position {
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
}

#proprietes-section .fixed-position h2 {
  margin-bottom: 20px;
  font-family: "Playfair Display";
  font-weight: normal;
  font-size: 48px;
  line-height: 64px;
}

@media (max-width: 1440.98px) {
  #proprietes-section .fixed-position h2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 1199.98px) {
  #proprietes-section .fixed-position h2 {
    line-height: 48px;
  }
}

@media (max-width: 991.98px) {
  #proprietes-section .fixed-position h2 {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  #proprietes-section .fixed-position h2 {
    font-size: 24px;
    line-height: 36px;
  }
}

@media (min-width: 1441px) {
  #proprietes-section .fixed-position h2 strong {
    display: block;
    font-size: 72px;
  }
}

@media (max-height: 800px) {
  #proprietes-section .fixed-position h2 strong {
    display: inline-block;
    font-size: 48px;
  }
}

@media (max-width: 991.98px) {
  #proprietes-section .fixed-position h2 strong {
    display: inline-block;
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  #proprietes-section .fixed-position h2 strong {
    display: block;
    font-size: 36px;
  }
}

#proprietes-section h5 {
  margin-bottom: 60px;
  color: rgba(0, 0, 0, 0.68);
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

@media (max-width: 1199.98px) {
  #proprietes-section h5 {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 991.98px) {
  #proprietes-section h5 {
    display: none;
  }
}

#proprietes-section .see-all-properties {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  padding: 25px 50px;
}

@media (max-width: 991.98px) {
  #proprietes-section .see-all-properties {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  #proprietes-section .see-all-properties {
    position: relative;
    margin-bottom: 20px;
  }
}

#proprietes-section .see-all-properties img {
  height: 14px;
  margin-left: 5px;
}

#proprietes-section .fp-slidesContainer {
  width: 100% !important;
  transform: none !important;
}

@media (max-width: 767.98px) {
  #proprietes-section .fp-slidesContainer {
    height: 475px;
  }
}

#proprietes-section .fp-slidesContainer .fp-slide {
  width: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 1.2s ease-in-out;
  overflow: hidden;
}

#proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper {
  position: absolute;
  top: 400px;
  left: 0;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-height: 800px) {
  #proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper {
    top: 260px;
  }
}

@media (max-width: 1440.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper {
    top: 300px;
  }
}

@media (max-width: 1199.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper {
    top: 260px;
  }
}

@media (max-width: 991.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper {
    position: relative;
    top: 0;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 767.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper {
    position: relative;
    height: 250px;
    top: 0;
    padding: 30px 15px;
    background: #fff;
    text-align: center;
    overflow: hidden;
  }
}

#proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper .label-new {
  display: inline-block;
  margin-bottom: 30px;
  padding: 7px 12px;
  background: #121212;
  color: #fff;
  font-family: "Playfair Display";
  font-weight: bold;
  font-size: 18px;
}

#proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper .title {
  font-size: 24px;
  line-height: 24px;
}

#proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper .title a {
  color: #121212;
}

@media (max-width: 767.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper .title {
    font-size: 18px;
  }
}

#proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper .city {
  font-size: 16px;
  line-height: 24px;
}

#proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper .description {
  margin-top: 20px;
  margin-bottom: 25px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 767.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper .description {
    display: none;
  }
}

@media (max-width: 767.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .text-content-wrapper .button {
    display: none;
  }
}

#proprietes-section .fp-slidesContainer .fp-slide .property-image {
  height: 100%;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .property-image {
    height: -webkit-calc(50% - 150px);
    height: -moz-calc(50% - 150px);
    height: calc(50% - 150px);
    top: 140px;
  }
}

@media (max-width: 767.98px) {
  #proprietes-section .fp-slidesContainer .fp-slide .property-image {
    top: 0px;
    height: 200px;
  }
}

#proprietes-section .fp-slidesContainer .fp-slide .property-image .image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  transition: transform 25s ease-in-out;
}

#proprietes-section .fp-slidesContainer .fp-slide.active {
  visibility: visible;
  opacity: 1;
}

#proprietes-section .fp-slidesContainer .fp-slide.active .property-image .image {
  -webkit-transform: scaleX(1.15) scaleY(1.15) translateZ(0);
  transform: scaleX(1.15) scaleY(1.15) translateZ(0);
  -webkit-transition: -webkit-transform 25s ease-in-out;
}

@media (max-width: 991.98px) {
  #proprietes-section .fp-controlArrow {
    display: none;
  }
}

#proprietes-section .fp-controlArrow.fp-prev {
  display: none;
}

#proprietes-section .fp-controlArrow.fp-next {
  right: 50%;
  margin-right: -33px;
  margin-top: -33px;
  height: 66px;
  width: 66px;
  background: #0d0d0d;
  border: none;
}

#proprietes-section .fp-controlArrow.fp-next:after {
  display: block;
  content: " ";
  position: relative;
  top: 27px;
  left: 14px;
  width: 38px;
  height: 12px;
  background-image: url("/wp-content/themes/tiz-wmp/assets/img/layout/slide-arrow-white.png");
  background-repeat: no-repeat;
  z-index: 1000;
}

#proprietes-section .fp-slidesNav {
  left: 50% !important;
  bottom: 50px;
  margin-left: -25% !important;
}

@media (max-width: 991.98px) {
  #proprietes-section .fp-slidesNav {
    bottom: 100px;
    left: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  #proprietes-section .fp-slidesNav {
    bottom: 160px;
  }
}

#proprietes-section .fp-slidesNav ul li {
  width: 10px;
  height: 10px;
  margin: 3.5px;
}

#proprietes-section .fp-slidesNav ul li a span {
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  margin: 0;
  background: transparent;
  border: 1px solid #090909;
}

#proprietes-section .fp-slidesNav ul li a.active span, #proprietes-section .fp-slidesNav ul li a:hover span {
  background: #090909;
}

#medias-section {
  background-image: url("/wp-content/themes/tiz-wmp/assets/img/layout/background-medias.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  color: #fff;
}

#medias-section h2 {
  margin-bottom: 20px;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: normal;
  font-size: 48px;
  line-height: 64px;
  text-shadow: 0px 18px 59px black;
}

@media (max-width: 991.98px) {
  #medias-section h2 {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  #medias-section h2 {
    font-size: 24px;
    line-height: 36px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-height: 800px) {
  #medias-section h2 {
    margin-bottom: 0px;
  }
}

@media (min-width: 1441px) {
  #medias-section h2 strong {
    display: block;
    font-size: 72px;
  }
}

@media (max-height: 800px) {
  #medias-section h2 strong {
    display: inline-block;
    font-size: 48px;
  }
}

@media (max-width: 991.98px) {
  #medias-section h2 strong {
    display: block;
    font-size: 48px;
  }
}

@media (max-width: 767.98px) {
  #medias-section h2 strong {
    display: block;
    font-size: 36px;
  }
}

#medias-section h5 {
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.68);
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

@media (max-width: 767.98px) {
  #medias-section h5 {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-height: 800px) {
  #medias-section h5 {
    margin-bottom: 10px;
  }
}

#medias-section #carousel-medias {
  width: 500px;
  padding: 0 15px;
  max-width: 100%;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #medias-section #carousel-medias {
    width: 700px;
    padding: 0;
  }
}

@media (min-width: 992px) {
  #medias-section #carousel-medias {
    width: 900px;
  }
}

@media (min-width: 1200px) {
  #medias-section #carousel-medias {
    width: 1100px;
  }
}

@media (min-width: 1441px) {
  #medias-section #carousel-medias {
    width: 1500px;
  }
}

#medias-section #carousel-medias .owl-stage .owl-item .media-item {
  display: block;
  height: 400px;
  text-decoration: none;
}

@media (max-height: 800px) {
  #medias-section #carousel-medias .owl-stage .owl-item .media-item {
    height: 350px;
  }
}

#medias-section #carousel-medias .owl-stage .owl-item .media-item .count {
  display: block;
  height: 25px;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  transition: all 0.5s;
}

#medias-section #carousel-medias .owl-stage .owl-item .media-item .image-wrapper {
  height: 325px;
  width: 100%;
  transition: all 0.5s;
  background: -moz-linear-gradient(90deg, #1e1d3c 0%, #290a59 49%, #0e2a5d 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0e2a5d), color-stop(51%, #290a59), color-stop(100%, #1e1d3c));
  background: -webkit-linear-gradient(90deg, #1e1d3c 0%, #290a59 49%, #0e2a5d 100%);
  background: -o-linear-gradient(90deg, #1e1d3c 0%, #290a59 49%, #0e2a5d 100%);
  background: -ms-linear-gradient(90deg, #1e1d3c 0%, #290a59 49%, #0e2a5d 100%);
  background: linear-gradient(0deg, #1e1d3c 0%, #290a59 49%, #0e2a5d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0e2a5d', endColorstr='#1e1d3c', GradientType=0);
}

@media (max-height: 800px) {
  #medias-section #carousel-medias .owl-stage .owl-item .media-item .image-wrapper {
    height: 275px;
  }
}

@media (max-width: 991.98px) {
  #medias-section #carousel-medias .owl-stage .owl-item .media-item .image-wrapper {
    height: 275px;
  }
}

@media (max-width: 767.98px) {
  #medias-section #carousel-medias .owl-stage .owl-item .media-item .image-wrapper {
    height: 225px;
  }
}

#medias-section #carousel-medias .owl-stage .owl-item .media-item .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 45px 0px black;
  transition: opacity 0.5s;
}

#medias-section #carousel-medias .owl-stage .owl-item .media-item .image-wrapper:hover img {
  opacity: 0.15;
}

#medias-section #carousel-medias .owl-stage .owl-item .media-item .excerpt {
  height: 50px;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  transition: all 0.5s;
}

#medias-section #carousel-medias .owl-stage .owl-item:not(.center) .image-wrapper {
  opacity: 0.1;
}

#medias-section #carousel-medias .owl-stage .owl-item:not(.center) .count, #medias-section #carousel-medias .owl-stage .owl-item:not(.center) .excerpt {
  color: rgba(255, 255, 255, 0.17);
}

#medias-section #carousel-medias .owl-nav {
  top: -200px;
  left: 0;
}

#medias-section #carousel-medias .owl-nav .owl-next, #medias-section #carousel-medias .owl-nav .owl-prev {
  display: inline-block;
  position: absolute;
}

#medias-section #carousel-medias .owl-nav .owl-next.disabled, #medias-section #carousel-medias .owl-nav .owl-prev.disabled {
  display: none;
}

#medias-section #carousel-medias .owl-nav .owl-prev {
  left: 10px;
}

@media (min-width: 768px) {
  #medias-section #carousel-medias .owl-nav .owl-prev {
    left: 20px;
  }
}

@media (min-width: 992px) {
  #medias-section #carousel-medias .owl-nav .owl-prev {
    left: 110px;
  }
}

@media (min-width: 1200px) {
  #medias-section #carousel-medias .owl-nav .owl-prev {
    left: 110px;
  }
}

@media (min-width: 1441px) {
  #medias-section #carousel-medias .owl-nav .owl-prev {
    left: 120px;
  }
}

#medias-section #carousel-medias .owl-nav .owl-next {
  right: 10px;
}

@media (min-width: 768px) {
  #medias-section #carousel-medias .owl-nav .owl-next {
    right: 20px;
  }
}

@media (min-width: 992px) {
  #medias-section #carousel-medias .owl-nav .owl-next {
    right: 110px;
  }
}

@media (min-width: 1200px) {
  #medias-section #carousel-medias .owl-nav .owl-next {
    right: 110px;
  }
}

@media (min-width: 1441px) {
  #medias-section #carousel-medias .owl-nav .owl-next {
    right: 120px;
  }
}

#medias-section .see-all-medias {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  padding: 25px 50px;
}

@media (max-width: 991.98px) {
  #medias-section .see-all-medias {
    position: relative;
    width: 100%;
  }
}

#medias-section .see-all-medias img {
  height: 14px;
  margin-left: 5px;
}

#contact-section {
  background: #f0f0f0;
}

@media (max-width: 767.98px) {
  #contact-section {
    background: #fff;
  }
}

#contact-section #pattern-contact {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 27%;
  background-color: #000000;
  background-image: url("/wp-content/themes/tiz-wmp/assets/svg/layout/pattern-contact.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 991.98px) {
  #contact-section #pattern-contact {
    width: 20%;
  }
}

@media (max-width: 767.98px) {
  #contact-section #pattern-contact {
    display: none;
  }
}

#contact-section #image-contact {
  position: absolute;
  height: 100%;
  top: 0;
  left: 27%;
  width: 73%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 991.98px) {
  #contact-section #image-contact {
    left: 20%;
    width: 80%;
  }
}

@media (max-width: 767.98px) {
  #contact-section #image-contact {
    display: none;
  }
}

#contact-section #contact-form {
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  #contact-section #contact-form {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #contact-section #contact-form {
    top: 50px;
    transform: translateY(0);
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 767.98px) {
  #contact-section #contact-form {
    top: 0px;
    transform: translateY(0);
    padding-left: 15px;
    padding-right: 15px;
  }
}

#contact-section #contact-title {
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  padding-left: 15%;
  padding-right: 15%;
  font-size: 48px;
  font-family: "Playfair Display";
  font-weight: normal;
  line-height: 48px;
  color: #fff;
}

@media (max-width: 991.98px) {
  #contact-section #contact-title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  #contact-section #contact-title {
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 24px;
    line-height: 36px;
    color: #121212;
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  #contact-section #contact-title strong {
    display: block;
    font-size: 48px;
  }
}

@media (max-width: 767.98px) {
  #contact-section #contact-title strong {
    display: block;
    font-size: 36px;
  }
}

@media (min-width: 1441px) {
  #contact-section #contact-title strong {
    display: block;
    font-size: 72px;
  }
}

#contact-section .send-message {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  padding: 25px 50px;
}

@media (max-width: 991.98px) {
  #contact-section .send-message {
    position: relative;
    width: 100%;
    bottom: 100px;
  }
}

@media (max-width: 767.98px) {
  #contact-section .send-message {
    bottom: 0;
  }
}

#contact-section .send-message img {
  height: 14px;
  margin-left: 5px;
}

#contact-section .open-footer {
  position: absolute;
  height: 66px;
  width: 66px;
  bottom: 0;
  left: 50%;
  margin-left: -33px;
  padding: 0;
  text-align: center;
  line-height: 66px;
  z-index: 1;
  transition: bottom linear .7s;
}

#contact-section .open-footer:hover, #contact-section .open-footer:active, #contact-section .open-footer:focus {
  background: #121212;
}

#contact-section .open-footer:hover img, #contact-section .open-footer:active img, #contact-section .open-footer:focus img {
  display: inline-block !important;
}

#contact-section .open-footer.up {
  bottom: 594px;
}

@media (max-width: 1199.98px) {
  #contact-section .open-footer.up {
    bottom: 538px;
  }
}

@media (max-width: 991.98px) {
  #contact-section .open-footer.up {
    bottom: 642px;
  }
}

@media (max-width: 767.98px) {
  #contact-section .open-footer.up {
    bottom: 475px;
  }
}

@media (max-width: 430px) {
  #contact-section .open-footer.up {
    bottom: 552px;
  }
}

@media (max-width: 320px) {
  #contact-section .open-footer.up {
    bottom: 592px;
  }
}

#contact-section .open-footer img {
  height: 26px;
  transition: all linear .7s;
}

#contact-section .open-footer.up img {
  transform: rotate(45deg);
}

footer#footer {
  position: absolute !important;
  bottom: -594px;
  left: 0;
  z-index: 1000;
  transition: bottom linear .7s;
}

@media (max-width: 1199.98px) {
  footer#footer {
    bottom: -538px;
  }
}

@media (max-width: 991.98px) {
  footer#footer {
    bottom: -642px;
  }
}

@media (max-width: 767.98px) {
  footer#footer {
    bottom: -475px;
  }
}

@media (max-width: 430px) {
  footer#footer {
    bottom: -552px;
  }
}

@media (max-width: 320px) {
  footer#footer {
    bottom: -592px;
  }
}

footer#footer.up {
  bottom: 0;
}

.fp-responsive .section .fp-tableCell {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .fp-responsive .section .fp-tableCell {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.fp-responsive .section,
.fp-responsive .section .fp-slide,
.fp-responsive .section .fp-tableCell {
  height: auto !important;
}
