@import "../base/variables";
@import "../tools/mixins";
@import "../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";

/*------------------------------------*\
		Page d'accueil
\*------------------------------------*/

#loader-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #060023;
  z-index: 100000;

  #logo-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 650px;
    max-width: 100%;
    margin: auto;

    @include media-breakpoint-down(md) {
      width: 80%;
    }
  }
}

header.header {
  background: transparent;
}

main {
  padding-top: 0;
  overflow-x: initial;
}

section {
  overflow: hidden;
}

#fp-nav {
  right: 0 !important;

  @include media-breakpoint-down(lg) {
    display: none !important;
  }

  ul {
    li {
      width: 60px;
      height: 25px;
      margin: 0;
      margin-bottom: 55px;
      padding-right: 45px;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        span {
          display: none;
        }
      }

      .fp-tooltip {
        width: auto;
        right: 0 !important;
        opacity: 1;
        font-family: "Nimbus Sans L";
        font-weight: bold;
        font-size: 13px;
        color: #202020;

        .hover {
          display: none;
        }

        &:after {
          display: block;
          opacity: 0;
          width: 60px;
          height: 2px;
          margin-top: 6px;
          content: " ";
          background-image: url($svg-dir + 'line.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      a:hover + .fp-tooltip {
        right: 20px !important;

        .hover {
          display: inline-block;
        }

        &:after {
          opacity: 1;
        }
      }

      a.active + .fp-tooltip {
        right: 20px !important;

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

#discover-company {
  position: fixed;
  left: 25px;
  top: 50%;
  z-index: 1000;
  font-size: 14px;
  transform-origin: 0 50%;
  transform: rotate(-90deg) translate(-50%, 50%);
  font-weight: bold;
  letter-spacing: 0.3em;
  color: rgba(49,49,49,0.45);
  text-decoration: none;
  text-transform: uppercase;

  @include media-breakpoint-down(lg) {
    display: none;
  }

  &:hover,&:focus,&:active {
    color: rgb(49,49,49);
  }

  img {
    width: 19px;
    height: 19px;

    &.white {
      display: none;
    }
  }
}

body.white {
  #fp-nav {

    .fp-tooltip {
      color: #fff;

      &:after {
        background-image: url($svg-dir + 'line-white.svg');
      }
    }
  }

  #discover-company {
    color: rgba(255,255,255,0.45);

    img {
      display: none;

      &.white {
        display: inline-block;
      }
    }

    &:hover,&:focus,&:active {
      color: #fff;
    }
  }
}

body.discover-white {
  #discover-company {
    color: rgba(255,255,255,0.45);

    img {
      display: none;

      &.white {
        display: inline-block;
      }
    }

    &:hover,&:focus,&:active {
      color: #fff;
    }
  }
}

#header-section {
  background: $dark-blue;
  text-align: center;
  box-shadow: -16px 25px 65px 0px rgba(11, 12, 17, 0.2);

  #logo {
    width: 650px;
    max-width: 100%;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      width: 80%;
      margin-top: 30px;
    }
  }

  .text {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 100;
    color: rgba(255,255,255,0.9);

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  button {
    color: rgba(255,255,255,0.9);
  }

  #background-video {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.37;

    @include media-breakpoint-down(md) {
      position: absolute;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url($img-dir + 'bg-tablette-portrait.jpg');
      opacity: 1;
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  #mouse-scroll {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
    animation: MoveUpDown 3s linear infinite;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    img {
      display: block;
      margin: 0 auto;

      &.mouse {
        width: 22px;
      }
    }

    @keyframes MoveUpDown {
      0%, 100% {
        bottom: 20px;
      }
      50% {
        bottom: 40px;
      }
    }
  }
}

#services-section {
  background: $dark-blue;
  color: #fff;

  h2 {
    margin-bottom: 30px;
    text-align: center;
    font-family: "Playfair Display";
    font-weight: normal;
    font-size: 48px;
    line-height: 64px;
    text-shadow: 0px 18px 59px rgba(0, 0, 0, 1);

    @include media-breakpoint-down(xl) {
      font-size: 48px;
    }

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 48px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-height: 800px) {
      margin-bottom: 20px;
    }

    strong {
      @include media-breakpoint-up(xxl) {
        display: block;
        font-size: 72px;
      }

      @media (max-height: 800px) {
        display: inline-block;
        font-size: 48px;
      }

      @include media-breakpoint-down(md) {
        display: block;
        font-size: 48px;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        font-size: 36px;
      }
    }
  }

  #pattern-bottom {
    position: absolute;
    width: 100%;
    height: 300px;
    bottom: 30px;
    background-image: url($svg-dir + 'pattern-services.svg');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.03;
  }

  #list-services {

    .owl-stage {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;

      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      .owl-item {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: auto !important;

        .service {
          text-align: center;
          padding-bottom: 80px;
          padding-left: 15px;
          padding-right: 15px;

          .picto {
            display: block;
            height: 100px;
            margin: auto;
            opacity: 0.29;

            @include media-breakpoint-down(xl) {
              height: 80px;
            }

            @media (max-height: 800px) {
              height: 80px;
            }
          }

          .title {
            margin-top: 20px;
            margin-bottom: 20px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.6em;
            font-size: 18px;
            line-height: 26px;
            opacity: 0.9;

            @media (max-height: 800px) {
              margin-top: 15px;
              margin-bottom: 15px;
            }

            @include media-breakpoint-down(md) {
              margin-top: 15px;
              margin-bottom: 15px;
            }
          }

          .description {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 20px;
            opacity: 0.68;

            @include media-breakpoint-down(xl) {
              font-size: 14px;
              line-height: 16px;
            }
          }

          .button {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0.9;

            &:hover, &:focus, &:active {
              opacity: 1;
            }
          }
        }
      }
    }

    .owl-nav {
      position: absolute;
      top: 80px;
      width: 100%;
      color: #fff;
      font-size: 32px;
      z-index: 1;

      img {
        height: 35px;
      }

      .owl-prev {
        float: left;
        left: 0px;
      }

      .owl-next {
        float: right;
        right: 0px;
      }

      .disabled {
        img {
          opacity: 0.5;
        }
      }
    }
  }
}

#bourse-section {
    background: #f0f0f0;

    @include media-breakpoint-down(sm) {
      background: #fff;
    }

    h2 {
        margin-bottom: 60px;
        text-align: center;
        font-family: "Playfair Display";
        font-weight: normal;
        font-size: 48px;
        line-height: 64px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 48px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px;
      }

      @media (max-height: 800px) {
        margin-bottom: 20px;
      }

      strong {
        @include media-breakpoint-up(xxl) {
          display: block;
          font-size: 72px;
        }

        @media (max-height: 800px) {
          display: inline-block;
          font-size: 48px;
        }

        @include media-breakpoint-down(md) {
          display: block;
          font-size: 48px;
        }

        @include media-breakpoint-down(sm) {
          display: block;
          font-size: 36px;
        }
      }
    }

  #filters-type {
    margin-bottom: 15px;

    .filter-type {
      margin-right: 5px;
      padding-left: 5px;
      padding-right: 5px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
        font-size: 12px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .sep {
    margin-top: 6px;
    padding: 10px;
    padding-bottom: 5px;
    font-weight: bold;
    background: #e8e8e8;
  }

  .list-table {
    display: none;
    width: 100%;
    border-spacing: 0 6px;

    &.loading {
      -webkit-filter: blur(8px);
      filter: blur(8px);
    }

    &.active {
      display: block;
    }

    .line {
      width: 100%;
      margin-top: 6px;
      cursor: pointer;
      border: 1px solid #090909;
      @include clearfix;
      transition: all .3s;

      &:nth-child(-n+5){
        display: block;
      }

      &:first-child {
        margin-top: 0;
      }

      > div {
        float: left;
        @include calc('width', '100%/3');
        padding: 20px 20px;
        padding-bottom: 12px;
        font-size: 20px;
        font-weight: bold;
        vertical-align : middle;

        @include media-breakpoint-only(md) {
          padding: 12px 12px;
          padding-bottom: 4px;
          font-size: 18px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }

        &.green {
          color: #64b614;
        }

        &.red {
          color: #b6142b;
        }

        &:nth-child(5) {
          font-size: 13px;
        }
      }

      &.active, &:hover {
        border-color: #260e5b;
        border-width: 3px;
      }
    }
  }

  #chart {
    height: 100%;
    max-height: 408px;
    width: 100%;
    background: rgba(9,9,9,0.03);

    @include media-breakpoint-down(md) {
      height: 360px;
      margin-bottom: 60px;
    }

    @include media-breakpoint-down(sm) {
      height: 400px;
      margin-bottom: 80px;
    }

    &.loading {
      -webkit-filter: blur(8px);
      filter: blur(8px);
    }

    .amcharts-zoom-out-bg, .amcharts-zoom-out-image, .amcharts-zoom-out-label {
      display: none;
    }
  }

  #filters-period {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(md) {
      top: 20px;
      left: 0;
    }

    .filter-period {
      display: inline-block;
      margin: 0 3px;
      padding: 10px 12px;
      padding-bottom: 6px;
      background: transparent;
      color: #313131;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;

      @include media-breakpoint-down(sm) {
        margin: 0;
        font-size: 14px;
      }

      &:hover, &:focus, &:active, &.active {
        background: #090909;
        color: #efefef;
      }
    }
  }

  .see-all-bourse {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 25px 50px;

    @include media-breakpoint-down(sm) {
      position: relative;
      width: 100%;
    }

    img {
      height: 14px;
      margin-left: 5px;
    }
  }
}

#proprietes-section {
    background: #f0f0f0;

    @include media-breakpoint-down(sm) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .fixed-position {
        position: absolute;
        top: 140px;
        left: 0;
        width: 50%;
        padding-left: 5%;
        padding-right: 5%;

        @include media-breakpoint-down(xl) {
          top: 80px;
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          top: 80px;
          text-align: center;
        }

        @include media-breakpoint-down(sm) {
          position: relative;
          top: 0;
          width: 100%;
          text-align: center;
          padding-left: 0;
          padding-right: 0;
        }

        h2 {
            margin-bottom: 20px;
            font-family: "Playfair Display";
            font-weight: normal;
            font-size: 48px;
            line-height: 64px;

          @include media-breakpoint-down(xl) {
            margin-bottom: 10px;
          }

          @include media-breakpoint-down(lg) {
            line-height: 48px;
          }

          @include media-breakpoint-down(md) {
            font-size: 32px;
            line-height: 48px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 36px;
          }

          strong {
            @include media-breakpoint-up(xxl) {
              display: block;
              font-size: 72px;
            }

            @media (max-height: 800px) {
              display: inline-block;
              font-size: 48px;
            }

            @include media-breakpoint-down(md) {
              display: inline-block;
              font-size: 32px;
              line-height: 48px;
            }

            @include media-breakpoint-down(sm) {
              display: block;
              font-size: 36px;
            }
          }
        }
    }

    h5 {
      margin-bottom: 60px;
      color: rgba(0,0,0,0.68);
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;

      @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 22px;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

  .see-all-properties {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 25px 50px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      margin-bottom: 20px;
    }

    img {
      height: 14px;
      margin-left: 5px;
    }
  }

    .fp-slidesContainer {
      width: 100% !important;
      transform: none !important;

        @include media-breakpoint-down(sm) {
            height: 475px;
        }

        .fp-slide {
          width: 100% !important;
          position: absolute;
          left: 0;
          top: 0;
          visibility: hidden;
          opacity: 0;
          transition: all 1.2s ease-in-out;
          overflow: hidden;

          .text-content-wrapper {
            position: absolute;
            top: 400px;
            left: 0;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;

            @media (max-height: 800px) {
              top: 260px;
            }

            @include media-breakpoint-down(xl) {
              top: 300px;
            }

            @include media-breakpoint-down(lg) {
              top: 260px;
            }


            @include media-breakpoint-down(md) {
              position: relative;
              top: 0;
              padding-left: 5%;
              padding-right: 5%;
            }

            @include media-breakpoint-down(sm) {
              position: relative;
              height: 250px;
              top: 0;
              padding: 30px 15px;
              background: #fff;
              text-align: center;
              overflow: hidden;
            }

            .label-new {
              display: inline-block;
              margin-bottom: 30px;
              padding: 7px 12px;
              background: #121212;
              color: #fff;
              font-family: "Playfair Display";
              font-weight: bold;
              font-size: 18px;
            }

            .title {
              font-size: 24px;
              line-height: 24px;

              a {
                color: $black;
              }

              @include media-breakpoint-down(sm) {
                  font-size: 18px;
              }
            }

            .city {
              font-size: 16px;
              line-height: 24px;
            }

            .description {
              margin-top: 20px;
              margin-bottom: 25px;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;

              @include media-breakpoint-down(sm) {
                display: none;
              }
            }

            .button {
              @include media-breakpoint-down(sm) {
                display: none;
              }
            }
          }

          .property-image {
            height: 100%;
            overflow: hidden;

            @include media-breakpoint-down(md) {
              @include calc('height', '50% - 150px');
              top: 140px;
            }

            @include media-breakpoint-down(sm) {
              top: 0px;
              height: 200px;
            }

            .image {
              object-fit: cover;
              object-position: center;
              width: 100%;
              height: 100%;
              transition: transform 25s ease-in-out;
            }
          }

          &.active {
            visibility: visible;
            opacity: 1;

            .property-image {
              .image {
                -webkit-transform: scaleX(1.15) scaleY(1.15) translateZ(0);
                transform: scaleX(1.15) scaleY(1.15) translateZ(0);
                -webkit-transition: -webkit-transform 25s ease-in-out;
              }
            }
          }
        }
    }

  .fp-controlArrow {
    @include media-breakpoint-down(md) {
      display: none;
    }

    &.fp-prev {
      display: none;
    }

    &.fp-next {
      right: 50%;
      margin-right: -33px;
      margin-top: -33px;
      height: 66px;
      width: 66px;
      background: #0d0d0d;
      border: none;

      &:after {
        display: block;
        content: " ";
        position: relative;
        top: 27px;
        left: 14px;
        width: 38px;
        height: 12px;
        background-image: url($img-dir + 'slide-arrow-white.png');
        background-repeat: no-repeat;
        z-index: 1000;
      }
    }
  }

  .fp-slidesNav {
    left: 50% !important;
    bottom: 50px;
    margin-left: -25% !important;

    @include media-breakpoint-down(md) {
      bottom: 100px;
      left: 0 !important;
      margin-left: 0 !important;
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      bottom: 160px;
    }

    ul {
      li {
        width: 10px;
        height: 10px;
        margin: 3.5px;

        a {
          span {
            width: 10px;
            height: 10px;
            top: 0;
            left: 0;
            margin: 0;
            background: transparent;
            border: 1px solid #090909;
          }

          &.active, &:hover {
            span {
              background: #090909;
            }
          }
        }
      }
    }
  }
}

#medias-section {
  background-image: url($img-dir + 'background-medias.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  color: #fff;

  h2 {
    margin-bottom: 20px;
    text-align: center;
    font-family: "Playfair Display";
    font-weight: normal;
    font-size: 48px;
    line-height: 64px;
    text-shadow: 0px 18px 59px rgba(0, 0, 0, 1);

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 48px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      line-height: 36px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-height: 800px) {
      margin-bottom: 0px;
    }

    strong {
      @include media-breakpoint-up(xxl) {
        display: block;
        font-size: 72px;
      }

      @media (max-height: 800px) {
        display: inline-block;
        font-size: 48px;
      }

      @include media-breakpoint-down(md) {
        display: block;
        font-size: 48px;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        font-size: 36px;
      }
    }
  }

  h5 {
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    text-align: center;
    color: rgba(255,255,255,0.68);
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;

    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-height: 800px) {
      margin-bottom: 10px;
    }
  }

  #carousel-medias {
    width: 500px;
    padding: 0 15px;
    max-width: 100%;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      width: 700px;
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 900px;
    }

    @include media-breakpoint-up(xl) {
      width: 1100px;
    }

    @include media-breakpoint-up(xxl) {
      width: 1500px;
    }

    .owl-stage {

      .owl-item {
        //width: 500px !important;

        .media-item {
          display: block;
          height: 400px;
          //width: 500px;
          text-decoration: none;

          @media (max-height: 800px) {
            height: 350px;
          }

          .count {
            display: block;
            height: 25px;
            text-align: right;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            transition: all 0.5s;
          }

          .image-wrapper {
            height: 325px;
            width: 100%;
            transition: all 0.5s;
            background: -moz-linear-gradient(90deg, rgba(30, 29, 60, 1) 0%, rgba(41, 10, 89, 1) 49%, rgba(14, 42, 93, 1) 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(14, 42, 93, 1)), color-stop(51%, rgba(41, 10, 89, 1)), color-stop(100%, rgba(30, 29, 60, 1)));
            background: -webkit-linear-gradient(90deg, rgba(30, 29, 60, 1) 0%, rgba(41, 10, 89, 1) 49%, rgba(14, 42, 93, 1) 100%);
            background: -o-linear-gradient(90deg, rgba(30, 29, 60, 1) 0%, rgba(41, 10, 89, 1) 49%, rgba(14, 42, 93, 1) 100%);
            background: -ms-linear-gradient(90deg, rgba(30, 29, 60, 1) 0%, rgba(41, 10, 89, 1) 49%, rgba(14, 42, 93, 1) 100%);
            background: linear-gradient(0deg, rgba(30, 29, 60, 1) 0%, rgba(41, 10, 89, 1) 49%, rgba(14, 42, 93, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0e2a5d', endColorstr='#1e1d3c', GradientType=0);

            @media (max-height: 800px) {
              height: 275px;
            }

            @include media-breakpoint-down(md) {
              height: 275px;
            }

            @include media-breakpoint-down(sm) {
              height: 225px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 1);
              transition: opacity 0.5s;
            }

            &:hover {
              img {
                opacity: 0.15;
              }
            }
          }

          .excerpt {
            height: 50px;
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 18px;
            line-height: 22px;
            color: #fff;
            transition: all 0.5s;
          }
        }

        &:not(.center) {
          .image-wrapper {
            opacity: 0.1;
          }

          .count, .excerpt {
            color: rgba(255, 255, 255, 0.17);
          }
        }
      }
    }

    .owl-nav {
      top: -200px;
      left: 0;

      .owl-next, .owl-prev {
        display: inline-block;
        position: absolute;

        &.disabled {
          display: none;
        }
      }

      .owl-prev {
        left: 10px;

        @include media-breakpoint-up(md) {
          left: 20px;
        }

        @include media-breakpoint-up(lg) {
          left: 110px;
        }

        @include media-breakpoint-up(xl) {
          left: 110px;
        }

        @include media-breakpoint-up(xxl) {
          left: 120px;
        }
      }

      .owl-next {
        right: 10px;

        @include media-breakpoint-up(md) {
          right: 20px;
        }

        @include media-breakpoint-up(lg) {
          right: 110px;
        }

        @include media-breakpoint-up(xl) {
          right: 110px;
        }

        @include media-breakpoint-up(xxl) {
          right: 120px;
        }
      }
    }
  }

  .see-all-medias {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 25px 50px;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
    }

    img {
      height: 14px;
      margin-left: 5px;
    }
  }
}

#contact-section {
  background: #f0f0f0;

  @include media-breakpoint-down(sm) {
    background: #fff;
  }
  
  #pattern-contact {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 27%;
    background-color: #000000;
    background-image: url($svg-dir + 'pattern-contact.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-down(md) {
      width: 20%;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  #image-contact {
    position: absolute;
    height: 100%;
    top: 0;
    left: 27%;
    width: 73%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-down(md) {
      left: 20%;
      width: 80%;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  #contact-form {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
      padding-left: 15%;
      padding-right: 15%;
    }

    @include media-breakpoint-only(md) {
      top: 50px;
      transform: translateY(0);
      padding-left: 5%;
      padding-right: 5%;
    }

    @include media-breakpoint-down(sm) {
      top: 0px;
      transform: translateY(0);
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  #contact-title {
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 48px;
    font-family: "Playfair Display";
    font-weight: normal;
    line-height: 48px;
    color: #fff;

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 48px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 24px;
      line-height: 36px;
      color: $black;
      text-align: center;
    }

    strong {
      @include media-breakpoint-down(md) {
        display: block;
        font-size: 48px;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        font-size: 36px;
      }

      @include media-breakpoint-up(xxl) {
        display: block;
        font-size: 72px;
      }
    }
  }

  .send-message {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 25px 50px;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
      bottom: 100px;
    }

    @include media-breakpoint-down(sm) {
      bottom: 0;
    }

    img {
      height: 14px;
      margin-left: 5px;
    }
  }

  .open-footer {
    position: absolute;
    height: 66px;
    width: 66px;
    bottom: 0;
    left: 50%;
    margin-left: -33px;
    padding: 0;
    text-align: center;
    line-height: 66px;
    z-index: 1;
    transition: bottom linear .7s;

    &:hover, &:active, &:focus {
      background: $black;

      img {
        display: inline-block !important;
      }
    }

    &.up {
      bottom: 594px;

      @include media-breakpoint-down(lg) {
        bottom: 538px;
      }

      @include media-breakpoint-down(md) {
        bottom: 642px;
      }

      @include media-breakpoint-down(sm) {
        bottom: 475px;
      }

      @media (max-width: 430px) {
        bottom: 552px;
      }

      @media (max-width: 320px) {
        bottom: 592px;
      }
    }

    img {
      height: 26px;
      transition: all linear .7s;
    }

    &.up {
      img {
        transform: rotate(45deg);
      }
    }
  }
}

footer#footer {
  position: absolute !important;
  bottom: -594px;
  left: 0;
  z-index: 1000;
  transition: bottom linear .7s;


  @include media-breakpoint-down(lg) {
    bottom: -538px;
  }

  @include media-breakpoint-down(md) {
    bottom: -642px;
  }

  @include media-breakpoint-down(sm) {
    bottom: -475px;
  }

  @media (max-width: 430px) {
    bottom: -552px;
  }

  @media (max-width: 320px) {
    bottom: -592px;
  }

  &.up {
    bottom: 0;
  }
}


.fp-responsive .section .fp-tableCell {
  padding-top: 60px;
  padding-bottom: 60px;

  @include media-breakpoint-only(sm) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.fp-responsive .section,
.fp-responsive .section .fp-slide,
.fp-responsive .section .fp-tableCell {
  height: auto !important;
}